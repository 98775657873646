import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import axios from 'axios';
import Home from './Home';
import Channel from './Channel';
import SimulVods from './SimulVods';


class Main extends Component {

	render(){
		return (
			<main>
				<Switch>
					<Route exact path='/' component={Home}/>
					<Route path='/channel/:login' component={Channel} />
					<Route path='/simulvods/:vodId' component={SimulVods}/>
				</Switch>
		  </main>
		)
	}
}

export default Main;
import React, {useEffect, useState} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";
import axios from 'axios';

import Player from './Player';
import VodTeasers from './VodTeasers';


const Home = ({}) => {


	let [recentVods, setRecentVods] = useState([])

	// Get reent vods
	useEffect(() => {
		axios.get('https://twatch.taox.space/api/recentvods/newer/48')
			.then(recentVods => {
				console.log(recentVods.data)
				setRecentVods(recentVods.data)
			});
	}, [])

	return (
		<div className="recent-vods">

			
			<div className="vod-grid">
				{recentVods.map((clusters, index) => {
					return (
						<div key={index} className="vod-cluster">
							<VodTeasers clusters={clusters} simul={false} channelInfo={true} />
						</div>
					)
				})}
			</div>

		</div>
	)
}

export default Home;
import React, {useEffect, useState} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";
import axios from 'axios';

import VodTeasers from './VodTeasers';

function SimulVods({channelData}){

	let { vodId } = useParams();

	let [simulVods, setsimulVods] = useState([])

	console.log(vodId)


	// Get get simulvods from vod id
	useEffect(() => {
		axios.get('http://twatch.taox.space/api/simulvods/'+vodId)
			.then(simulVods => {
				console.log(simulVods.data)
				setsimulVods(simulVods.data)
			});
	}, [vodId])

	return (
		<div className="simulvods">
			<div className="vod-grid">
				{simulVods.map((clusters, index) => {
					return (
						<div key={index} className="vod-cluster">
							<VodTeasers clusters={clusters} channelInfo={true} simul={true} vodId={vodId} />
						</div>
					)
				})}
			</div>

		</div>
	)

}

export default SimulVods;
import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';

import './App.sass';
import './styles/_menu.sass';
import './styles/_channel.sass';
import './styles/_vodgrid.sass';

import Sidebar from './components/Sidebar';
import Main from './components/Main';

// This is a container component
const App = () => {

	const [offlineChannels, setOfflineChannels] = useState([])
	const [liveChannels, setLiveChannels] = useState([])


	// Get function
	const getChannels = () => {
		axios.get('https://twatch.taox.space/api/getchannels')
			.then(channelsResult => {
				// Set channels to state
				let res = channelsResult.data
				// console.log(res.channels)
				setOfflineChannels(res.channels)
				setLiveChannels(res.live)
			});
	}



	// Run get
	useEffect(() => {
		getChannels()
		const interval=setInterval(()=>{
			console.log('interval run')
			getChannels()
		}, 30000)

		return()=>clearInterval(interval)
	}, [])


	// Render basic layout
	return (
	<div className="App">
		<Sidebar liveChannels={liveChannels} offlineChannels={offlineChannels} />
		<Main />
	</div>
	);
}

export default App;

import React, {useEffect, useState} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";
import axios from 'axios';

import Player from './Player';
import VodTeasers from './VodTeasers';

function Channel({channelData}){

	let { login } = useParams();

	document.title = login

	let isLive = (document.querySelector('.live-channels li[data-login="'+login+'"]')) ? true : false

	let [channelVods, setChannelVods] = useState([])

	// console.log(channelVods)


	// Get vods from channel
	useEffect(() => {
		axios.get('https://twatch.taox.space/api/vods/'+login)
			.then(channelVods => {
				console.log(channelVods.data)
				setChannelVods(channelVods.data)
			});
	}, [login])

	return (
		<div className="channel">

			{ isLive ? <Player login={login} /> : '' }
			
			<div className="vod-grid">
				{channelVods.map((clusters, index) => {
					return (
						<div key={index} className="vod-cluster">
							<VodTeasers clusters={clusters} simul={false} />
						</div>
					)
				})}
			</div>

		</div>
	)

}

export default Channel;
import React, {useEffect, useState} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";


function Player({login}){

	let playerIframe = '<iframe src="https://player.twitch.tv/?channel='+login+'&amp;parent=twatch.taox.space&amp;scrolling=no&amp;muted=false&amp;autoplay=true" frameborder="0" scrolling="no" allowfullscreen="no"></iframe>';

	let chatIframe = '<iframe frameborder="0" scrolling="no" src="https://www.twitch.tv/embed/'+login+'/chat?&amp;parent=twatch.taox.space&amp;darkpopout"></iframe>';

	return (
		<div className="player">
			<div className="player-embed" dangerouslySetInnerHTML={{__html: playerIframe}}>
			</div>
			<div className="chat-embed" dangerouslySetInnerHTML={{__html: chatIframe}}>
			</div>
		</div>
	)

}

export default Player;
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'


const VodTeasers = ({clusters, channelInfo=false, simul=false, vodId=null}) => {

	function parseMoreChapters(e){
		let lis = e.target.closest('ul').querySelectorAll('.more-chapter')
		let chapters = Array.from(lis).map(item => item.innerText)
		e.target.setAttribute('title', chapters.join('\n'))
	}

	function ChaptersMulti(chapters){
		let chaptersArray = chapters.chapters

		if( chaptersArray.length > 1 ){
			console.log('more')
			return (
				chaptersArray.map((chapter,index) => {
					if(index == 0){
						return (
							<div key="more-teaser" className="more-teaser" onMouseOver={(e) => {
								parseMoreChapters(e)
						   		}}>
								<li key={chapter+index}>{chapter.description}</li>
								<span>+ {chaptersArray.length-1} more</span>
							</div>
						)
					} else {
						return (
							<li key={chapter+index} className="more-chapter">{chapter.description}</li>
						)
					}
				})
			)
			// return (
			// 	<h4>Chapters</h4>
			// )
		} else {
			console.log('single chapter')
			return(
				<ul>
					<li key={chaptersArray[0].description}>{chaptersArray[0].description}</li>
				</ul>
			)
		}
	}


	function ShowChannelInfo({vodChannel, vodPicture}){
		return(
			<div className="channel-info">
				<img src={vodPicture} />
				<div className="channel-title">{vodChannel}</div>
			</div>
		)
	}


	
	// console.log(clusters)
	return (
		clusters.map((vod, vIndex) => {
			return (
				<div key={vod.vod_id} className="vod">
					<div className="vod-thumb">
						<img src={vod.vod_thumb} />
						<div className="duration">
							{vod.duration_hms}
						</div>
						<div className="ago">
							{vod.ago}
						</div>

						{ channelInfo ? <ShowChannelInfo vodChannel={vod.login} vodPicture={vod.profile_picture}></ShowChannelInfo> : '' }
					</div>

					<h3>
						<a href={vod.vod_link} target="_blank">
							{vod.vod_title}
						</a>
					</h3>

					<div className="categories">
						<ul>
							<ChaptersMulti chapters={vod.vod_chapters} />
						</ul>
					</div>

					<div className="options-button">
						<svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15">
						<g id="Group_15" data-name="Group 15" transform="translate(-883 -286)">
							<circle id="Ellipse_37" data-name="Ellipse 37" cx="1.5" cy="1.5" r="1.5" transform="translate(883 286)" fill="#fff"/>
							<circle id="Ellipse_38" data-name="Ellipse 38" cx="1.5" cy="1.5" r="1.5" transform="translate(883 292)" fill="#fff"/>
							<circle id="Ellipse_39" data-name="Ellipse 39" cx="1.5" cy="1.5" r="1.5" transform="translate(883 298)" fill="#fff"/>
						</g>
						</svg>

						<div className="options-menu">
							<ul>
								<li className="find-simul"><Link to={'/simulvods/' + vod.vod_id}>Find simulvods</Link></li>
								{simul ? <li className=""><a href={`https://twitchmultivod.com/#/${vodId}/${vod.vod_id}`} target="_blank">View on Twitchmultivod</a></li> : <li className=""><a href={`https://twitchmultivod.com/#/${vod.vod_id}`}>View on Twitchmultivod</a></li> }
							</ul>
						</div>
					</div>

				</div>
			)
		})
	)

}

export default VodTeasers;
import React from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

function Sidebar({liveChannels, offlineChannels}){

	// console.log(liveChannels)
	// console.log(channels)

	function mouseOverSidebar(){
		document.querySelector('main').classList.add('skrrt')
	}

	function mouseOutSidebar(){
		document.querySelector('main').classList.remove('skrrt')
	}


	return (
		<nav className="channels-sidebar" onMouseOver={mouseOverSidebar} onMouseOut={mouseOutSidebar}>
			<div className="home sidebar-button">
					<div className="icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="14.849" height="14.849" viewBox="0 0 14.849 14.849">
						<rect id="Rectangle_71" data-name="Rectangle 71" width="3" height="12" transform="translate(8.485) rotate(45)" fill="#fff"/>
						<rect id="Rectangle_72" data-name="Rectangle 72" width="3" height="12" transform="translate(12.728 4.243) rotate(45)" fill="#fff"/>
						</svg>
					</div>
					<span>Home</span>
					<Link to="/"></Link>
			</div>

			<ul className="live-channels">
			{liveChannels.map(ch => (
				<li key={ch.channel_id} data-login={ch.login}>
					<Link to={'/channel/' + ch.login}>
						<img src={ch.profile_picture} />
						<div className="live-data">
							<span className="display-name">{ch.displayname}</span>
							<span className="category">{ch.livedata.game_name}</span>
						</div>
					</Link>
				</li>
			))}
			</ul>

			<ul className="offline-channels">
			{offlineChannels.map(ch => (
				<li key={ch.channel_id}>
					<Link to={'/channel/' + ch.login}>
						<img src={ch.profile_picture} />
						<div className="live-data">
							<span className="display-name">{ch.displayname}</span>
						</div>
					</Link>
				</li>
			))}
			</ul>
		</nav>
	)

}

export default Sidebar;